<template>
  <v-card class="chats_container" v-show="show_chats">
    <v-card-title class="chats_container_title">
      <h2>Messages</h2>
    </v-card-title>
    <p class="chats_warning_message">
      Please remember <strong>NEVER</strong> ask for or reveal any contact
      information and if you see anyone doing this please raise this with us
      using the help desk icon in the bottom right.
    </p>
    <v-card-text style="padding-top: 16px">
      <v-list two-line class="list" v-if="chats.length">
        <v-list-item
          class="chat_item"
          v-for="chat in chats"
          :key="chat.ID"
          ripple
          @click="open(chat.id)"
        >
          <v-list-item-avatar
            v-if="getOtherUser(chat.participants).avatar != null"
          >
            <v-img :src="getOtherUser(chat.participants).avatar"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="chats_content_title">
              {{
                `${getOtherUser(chat.participants).first_name} ${
                  getOtherUser(chat.participants).last_name
                } `
              }}
            </v-list-item-title>
            <v-list-item-text class="chats_content_preview">
              <p class="last_message" v-if="chat.last_message">
                {{ chat.last_message.content }}
              </p>
              <p class="last_message_date">
                <template v-if="chat.last_message">{{
                  getDate(chat.last_message.created_at)
                }}</template>
                <template v-else>{{ getDate(chat.created_at) }}</template>
              </p>
            </v-list-item-text>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-else id="no_messages_text">
        <h2>
          You do not have any messages yet. Once a you receive a response from a
          company you will be able to message them as you please.
        </h2>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { SHOW_CHATS } from "@/config";
import { GetMessages } from "@/api/messages/get_messages.js";
export default {
  data() {
    return {
      show_chats: SHOW_CHATS
    };
  },
  methods: {
    getOtherUser(participants) {
      for (const [id, participant] of Object.entries(participants)) {
        if (id !== this.self) {
          return participant;
        }
      }
    },
    getDate(timestamp) {
      var date = new Date(timestamp);
      var messageDate = "";
      messageDate +=
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
      return messageDate;
    },
    async open(id) {
      await GetMessages(id);
      this.$router.push({
        name: "chat",
        params: { id }
      });
    }
  },
  computed: {
    chats() {
      return this.$store.getters.sortedChats;
    },
    self() {
      return this.$store.getters.self;
    }
  }
};
</script>

<style scoped>
.chats_container {
  width: 100%;
  margin: 50px auto;
  overflow-y: auto;
}

.chats_warning_message {
  padding: 16px 16px 0;
  margin-bottom: 0;
}

.chats_container_title {
  background-color: var(--v-primary-base);
  color: #ffffff;
}

.chat_item {
  padding: 0;
}

.chats_content_title {
  font-size: 1.3rem;
}

.chats_content_preview {
  color: #808080;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.chats_content_preview p {
  margin: 0;
}

.last_message {
  max-width: 600px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.last_message_date {
  float: right;
  font-size: 0.8rem;
}

#no_messages_text {
  margin: 15px auto 0 auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1366px) {
  .chats_container {
    max-width: 950px;
    width: 95%;
    margin: 0 auto;
    max-height: 550px;
  }
}
</style>
